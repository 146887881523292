import {createTheme} from '@mui/material/styles';

const defaultTheme = createTheme({});

const theme = createTheme({
    shape: {
        borderRadius: 6,
    },
    typography: {
        fontFamily: 'Titillium Web, sans-serif',
    },
    palette: {
        primary: {
            main: '#FFD100',
            light: 'rgba(255, 209, 0, 0.5)',
        },
        secondary: {
            main: '#57585B',
            light: '#E4E4E4',
        },
        error: {
            main: '#B0001F',
            light: '#FDECEA',
        },
        background: {
            default: '#EDEDED',
        },
    },
    components: {
        MuiCardActions: {
            styleOverrides: {
                root: {
                    padding: defaultTheme.spacing(2),
                },
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    padding: `${defaultTheme.spacing(2)}px ${defaultTheme.spacing(3)}px`,
                },
            },
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    '&:last-child > td': {
                        borderBottom: 0,
                    },
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                head: {
                    '@media print': {
                        lineHeight: '0.9rem',
                    },
                },
                sizeSmall: {
                    '@media print': {
                        padding: `3px 10px 3px 10px`,
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    backgroundColor: 'white',
                    '&.Mui-focused fieldset': {
                        border: '1px solid #D0CBBD !important',
                    },
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                sizeSmall: {
                    fontSize: '0.875rem'
                },
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    borderColor: '#FFD100',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: 600,
                    fontSize: '0.8rem',
                    height: '38px',
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    fontWeight: 600,
                    fontSize: '1rem',
                    backgroundColor: '#D7DBD7',
                    borderRadius: '6px 6px 0px 0px',
                    '&.Mui-selected': {
                        backgroundColor: 'white',
                    },
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    display: 'none',
                }
            },
        },
    },
});

export default theme;
