import {Menu as MenuIcon} from '@mui/icons-material';
import {
    AppBar,
    Drawer,
    IconButton,
    Theme,
    Toolbar,
    useMediaQuery,
    useScrollTrigger,
    Container,
} from '@mui/material';
import {createStyles, makeStyles} from '@mui/styles';
import clsx from 'clsx';
import {useUser} from 'contexts/user';
import React, {FC} from 'react';
import * as settings from 'settings';
import {useToggle} from 'core/hooks'
import {AppFooter} from './app-footer';
import AppMenu from './app-menu';
import UserMenu from './user-menu';

interface LayoutProps {
    children: React.ReactNode;
}

interface LogoProps {
    height: number;
}

const Logo: FC<LogoProps> = ({height}) => {
    const classes = useStyles({height});
    const image = require('./assets/logo.svg');
    return (
        <div className={classes.logo}>
            <img src={image} alt={settings.SITE_NAME} onClick={() => window.location.replace('/')} />
        </div>
    );
};

const Layout: FC<LayoutProps> = ({children}) => {
    const {open, setClose, toggle} = useToggle();
    const elevateAppBar = useScrollTrigger({disableHysteresis: true, threshold: 0});
    const classes = useStyles({elevateAppBar});
    const {user} = useUser();
    const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    return (
        <div className={classes.root}>
            <AppBar className={classes.appBar} elevation={elevateAppBar ? 4 : 0}>
                <Container>
                    <Toolbar className={classes.toolBar}>
                        <Logo height={40} />
                        <div className={classes.menu}>
                            {!smDown && (
                                <UserMenu />
                            )}
                            {user && (
                                <IconButton
                                    edge='end'
                                    color='primary'
                                    onClick={() => toggle()}>
                                    <MenuIcon fontSize='large' />
                                </IconButton>
                            )}
                        </div>
                    </Toolbar>
                </Container>
            </AppBar>
            <Drawer
                open={open}
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
                anchor='right'
                onClose={() => setClose()}
            >
                <AppMenu setClose={setClose} toggle={toggle} />
            </Drawer>
            <div className={classes.toolbarContentSpacer} />
            <main className={classes.main}>
                {children}
            </main>
            <AppFooter />
        </div>
    );
};

export default Layout;

const drawerWidth = 360;

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
    },
    main: {
        flex: 1,
        display: 'flex',
        alignItems: 'flex-start',
    },
    appBar: (props: any) => ({
        background: '#FFF',
        borderBottom: `${props.elevateAppBar ? 0 : 1}px solid ${theme.palette.divider}`,
    }),
    toolBar: {
        padding: theme.spacing(0),
        justifyContent: 'space-between',
    },
    menu: {
        display: 'flex',
        alignItems: 'center',
    },
    logo: (props: any) => ({
        display: 'flex',
        marginTop: 4, // Adjust vertical alignment
        alignItems: 'center',
        cursor: 'pointer',
        '& img': {
            width: 'auto',
            height: props.height,
        },
    }),
    drawer: {
        width: drawerWidth,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    toolbarContentSpacer: {
        ...theme.mixins.toolbar
    },
    loading: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
}));
