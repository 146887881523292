import {ApolloError} from '@apollo/client';
import {Alert, AlertTitle, Button, Theme} from '@mui/material';
import {createStyles, makeStyles} from '@mui/styles';
import {FC} from 'react';

interface GraphQLErrorsProps {
    errors: {
        items?: string[]
    };
    refetch?: () => any;
}

export const GraphQLErrors: FC<GraphQLErrorsProps> = ({errors, refetch}) => {
    const classes = useStyles();

    const refetchButton = refetch && (
        <Button color='inherit' size='small' onClick={() => refetch()}>
            Retry
        </Button>
    );

    return (
        <div className={classes.root}>
            {errors.items?.map((item, index) => (
                <Alert key={index} severity='warning' action={refetchButton}>
                    <AlertTitle>GraphQL error</AlertTitle>
                    {item}
                </Alert>
            ))}
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));
