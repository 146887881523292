import {ApolloProvider} from '@apollo/client';
import {CssBaseline, ThemeProvider} from '@mui/material';
import {apolloClient} from 'api';
import Layout from 'components/layout';
import {NextPageContext} from 'next';
import App, {AppContext, AppProps} from 'next/app';
import Head from 'next/head';
import {UserProvider} from '../contexts/user';
import {SnackbarProvider} from 'notistack';

import theme from '../theme';

function MyApp({Component, pageProps}: AppProps) {
    return (
        <>
            <Head>
                <title>EXPERIENCIA NIDERA</title>
                <meta name='viewport' content='width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no' />
            </Head>
            <ThemeProvider theme={theme}>
                <SnackbarProvider>
                    <ApolloProvider client={apolloClient}>
                        <UserProvider>
                            <CssBaseline />
                            <Layout>
                                <Component {...pageProps} />
                            </Layout>
                        </UserProvider>
                    </ApolloProvider>
                </SnackbarProvider>
            </ThemeProvider>
        </>
    );
}

// Method with getInitialProps to fix the env variables not loading correctly
MyApp.getInitialProps = async (appContext: NextPageContext & AppContext) => {
    const appProps = await App.getInitialProps(appContext)
    return {...appProps}
}

export default MyApp;
