import {Close as CloseIcon} from '@mui/icons-material';
import {List, Theme, IconButton} from '@mui/material';
import {createStyles, makeStyles} from '@mui/styles';
import React, {FC} from 'react';

import AppMenuLink from './app-menu-link';
import UserMenu from './user-menu';

interface AppMenuProps {
    setClose(): void;
    toggle(): void;
}

const AppMenu: FC<AppMenuProps> = ({setClose, toggle}) => {
    const classes = useStyles();
    const sso_url = 'https://sso.experiencianidera.com'

    return (
        <>
            <div className={classes.header}>
                <UserMenu />
                <IconButton onClick={() => setClose()} color='secondary'>
                    <CloseIcon fontSize='large' />
                </IconButton>
            </div>
            <List component='nav' className={classes.list}>
                <>
                    <AppMenuLink href='/home' label='Inicio' fontSize='1.2rem' toggle={toggle} />
                    <AppMenuLink href='/cotizacion' label='Nueva cotización' fontSize='1.2rem' toggle={toggle} />
                    <AppMenuLink href='/recomendacion' label='Nueva recomendación' fontSize='1.2rem' toggle={toggle} />
                    <AppMenuLink href='/mis-cotizaciones' label='Mis cotizaciones' fontSize='1.2rem' toggle={toggle} />
                    <AppMenuLink href='/mis-recomendaciones' label='Mis recomendaciones' fontSize='1.2rem' toggle={toggle} />
                    <AppMenuLink href='/estadisticas' label='Estadísticas' fontSize='1.2rem' toggle={toggle} />
                    <AppMenuLink href={`${sso_url}/password/change`} label='Cambiar contraseña' fontSize='1.2rem' toggle={toggle} />
                    <AppMenuLink
                        href='https://www.experiencianidera.com/apps/biblioteca.php'
                        label={'Ingresar a la Biblioteca'}
                        fontSize='1.2rem'
                        toggle={toggle}
                    />
                    <AppMenuLink href={`${sso_url}/logout`} label='Salir' fontSize='1.2rem' toggle={toggle} />
                </>
            </List>
        </>
    );
};

export default AppMenu;

const useStyles = makeStyles((theme: Theme) => createStyles({
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: `${theme.spacing(1.5)} ${theme.spacing(2)} ${theme.spacing(1.5)}`,
        backgroundColor: theme.palette.primary.main,
    },
    list: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.primary.main,
        height: '100%',
        letterSpacing: '0.2rem',
        '& > a': {
            paddingTop: theme.spacing(1),
            boxShadow: '0px -10px 10px 0px rgba(0, 0, 0, .2) ',
        },
    },
}));
