import getConfig from 'next/config';

export const SITE_NAME = 'Experiencia Nidera';

export const LOGIN_URL = '/users/login';
export const LOGOUT_URL = '/users/logout';

const {publicRuntimeConfig} = getConfig();

export const SSO_UI_URL = publicRuntimeConfig.ssoUiUrl;
export const LANGUAGE_CODE = publicRuntimeConfig.languageCode;
export const ENVIRONMENT = publicRuntimeConfig.environment;
export const CURRENCY_CODE = publicRuntimeConfig.currencyCode;

export const TIMEZONE = -3;
export const DATE_FORMAT = 'DD/MM/YYYY';
export const DATETIME_FORMAT = 'DD/MM/YY HH:mm:ss';

export const MAX_DECIMALS = publicRuntimeConfig.maxDecimals;
