import {Collapse, List, ListItem, ListItemIcon, ListItemText, Theme} from '@mui/material';
import {createStyles, makeStyles} from '@mui/styles';
import Link from 'next/link';
import {useRouter} from 'next/router';
import React, {FC, useState} from 'react';

interface AppMenuLinkProps {
    href: string;
    label: string;
    icon?: React.ReactElement;
    children?: React.ReactNode,
    newTab?: boolean,
    fontSize?: string,
    toggle(): void;
}

const AppMenuLink: FC<AppMenuLinkProps> = ({href, label, icon, children, newTab, fontSize, toggle}) => {
    const classes = useStyles();
    const router = useRouter();

    const isExternalLink = href.startsWith('https://') || href.startsWith('http://');
    const isExpandable = Boolean(children);
    const [expanded, setExpanded] = useState<boolean>(router.pathname.startsWith(href));

    const linkIsActive = !isExpandable && (
        href === '/'
            ? router.pathname === href
            : router.pathname.startsWith(href)
    );

    const button = (
        <ListItem
            button={true}
            component={isExpandable ? 'button' : 'a'}
            selected={linkIsActive}
            onClick={() => {
                toggle()
                isExpandable && setExpanded(!expanded)
            }}>
            {icon && (
                <ListItemIcon>
                    {React.cloneElement(icon, {color: linkIsActive ? 'secondary' : 'inherit'})}
                </ListItemIcon>
            )}
            <ListItemText primary={label} primaryTypographyProps={{style: {fontSize: fontSize, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}} />
        </ListItem>
    );

    if (isExternalLink || newTab) {
        return React.cloneElement(button, {href, target: '_blank'});
    }

    return (
        <>
            {isExpandable ? button : <Link href={href} passHref={true}>{button}</Link>}
            {isExpandable && (
                <Collapse in={expanded}>
                    <List disablePadding={true} className={classes.nested}>
                        {children}
                    </List>
                </Collapse>
            )}
        </>
    );
};

export default AppMenuLink;


const useStyles = makeStyles((theme: Theme) => createStyles({
    nested: {
        paddingLeft: theme.spacing(2),
        backgroundColor: theme.palette.grey[100],
    },
}));
