import {ApolloClient, InMemoryCache} from '@apollo/client';
import {createUploadLink} from 'apollo-upload-client';


export const apolloClient = new ApolloClient({
    cache: new InMemoryCache(),
    link: createUploadLink({
        uri: '/graphql/',
        credentials: 'include',
    }),
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'cache-first',
        },
    },
});
