import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T;
export type InputMaybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: string;
  DateTime: string;
  GenericScalar: any;
  JSONString: any;
  UUID: string;
};

export type AccountType = {
  __typename?: 'AccountType';
  contacts: Array<ContactType>;
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  externalId: Scalars['String'];
  id: Scalars['Int'];
  imported?: Maybe<Scalars['DateTime']>;
  locations: Array<LocationType>;
  modified: Scalars['DateTime'];
  name: Scalars['String'];
  organizations: Array<OrganizationType>;
  taxId: Scalars['String'];
};

export type AmountDatum = {
  __typename?: 'AmountDatum';
  amount: Scalars['Float'];
  average?: Maybe<Scalars['Float']>;
  count: Scalars['Int'];
  label: Scalars['String'];
  percentage?: Maybe<Scalars['Float']>;
};

export type AnalyticsFilters = {
  category?: InputMaybe<Scalars['Int']>;
  dateFrom: Scalars['String'];
  dateTo: Scalars['String'];
  event?: InputMaybe<Scalars['Int']>;
  organization?: InputMaybe<Scalars['Int']>;
  userCategory?: InputMaybe<Scalars['String']>;
  users: Array<InputMaybe<Scalars['Int']>>;
};

export type BandType = {
  __typename?: 'BandType';
  category: CategoryType;
  discount: Scalars['Float'];
  id: Scalars['Int'];
  name: Scalars['String'];
  quoteitemSet: Array<QuoteItemType>;
};

export type CategoriesDatum = {
  __typename?: 'CategoriesDatum';
  amount?: Maybe<Scalars['Float']>;
  category: Scalars['String'];
  categoryId: Scalars['Int'];
  count: Scalars['Int'];
};

export type CategoryType = {
  __typename?: 'CategoryType';
  bands: Array<BandType>;
  goodPracticesPageInPdf: Scalars['Boolean'];
  id: Scalars['Int'];
  name: Scalars['String'];
  order: Scalars['Int'];
  paymentmethoddiscountpercategory?: Maybe<PaymentMethodDiscountPerCategoryType>;
  products: Array<ProductType>;
  published: Scalars['Boolean'];
  quoteitemSet: Array<QuoteItemType>;
};

export type ComboDiscountType = {
  __typename?: 'ComboDiscountType';
  discount1: Scalars['Float'];
  discount2: Scalars['Float'];
  id: Scalars['Int'];
  products1: Array<ProductType>;
  products2: Array<ProductType>;
  quoteitemSet: Array<QuoteItemType>;
  type?: Maybe<ComboTypeType>;
};

export type ComboTypeType = {
  __typename?: 'ComboTypeType';
  combodiscountSet: Array<ComboDiscountType>;
  id: Scalars['Int'];
  name: Scalars['String'];
  quoteitemSet: Array<QuoteItemType>;
};

export type ContactOptionType = {
  __typename?: 'ContactOptionType';
  account?: Maybe<AccountType>;
  contact?: Maybe<ContactType>;
  email?: Maybe<Scalars['String']>;
  filterLabel?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  locations?: Maybe<Array<Maybe<LocationType>>>;
  name?: Maybe<Scalars['String']>;
  organizations?: Maybe<Array<Maybe<OrganizationType>>>;
  phone?: Maybe<Scalars['String']>;
  taxId?: Maybe<Scalars['String']>;
};

export type ContactType = {
  __typename?: 'ContactType';
  account: AccountType;
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  displayName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  externalId: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['Int'];
  imported?: Maybe<Scalars['DateTime']>;
  lastName: Scalars['String'];
  modified: Scalars['DateTime'];
  phone: Scalars['String'];
  quoteSet: Array<QuoteType>;
  recommendationSet: Array<RecommendationType>;
};

export type CreateQuote = {
  __typename?: 'CreateQuote';
  errors?: Maybe<Scalars['GenericScalar']>;
  quote?: Maybe<QuoteType>;
  success: Scalars['Boolean'];
};

export type CreateRecommendation = {
  __typename?: 'CreateRecommendation';
  errors?: Maybe<Scalars['GenericScalar']>;
  recommendation?: Maybe<RecommendationType>;
  success: Scalars['Boolean'];
};

export type DateSeriesDatum = {
  __typename?: 'DateSeriesDatum';
  amount?: Maybe<Scalars['Float']>;
  count: Scalars['Int'];
  date: Scalars['Date'];
  groupBy?: Maybe<Scalars['String']>;
};

export type DiscountLimitType = {
  __typename?: 'DiscountLimitType';
  codeName: Scalars['String'];
  id: Scalars['Int'];
  maxRate: Scalars['Float'];
  name: Scalars['String'];
};

export type EventType = {
  __typename?: 'EventType';
  endDate: Scalars['DateTime'];
  id: Scalars['Int'];
  label?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  pdfCover?: Maybe<Scalars['String']>;
  quoteEmailClosing: Scalars['String'];
  quoteEmailOpening: Scalars['String'];
  quoteEmailSubject: Scalars['String'];
  quoteSet: Array<QuoteType>;
  recommendationEmailClosing: Scalars['String'];
  recommendationEmailOpening: Scalars['String'];
  recommendationEmailSubject: Scalars['String'];
  recommendationSet: Array<RecommendationType>;
  startDate: Scalars['DateTime'];
};

export type LocationType = {
  __typename?: 'LocationType';
  accountSet: Array<AccountType>;
  children: Array<LocationType>;
  code: Scalars['String'];
  id: Scalars['Int'];
  level: Scalars['Int'];
  lft: Scalars['Int'];
  name: Scalars['String'];
  parent?: Maybe<LocationType>;
  path?: Maybe<Scalars['String']>;
  rght: Scalars['Int'];
  treeId: Scalars['Int'];
};

export type OrganizationType = {
  __typename?: 'OrganizationType';
  accounts: Array<AccountType>;
  cuit: Scalars['String'];
  id: Scalars['Int'];
  isActive: Scalars['Boolean'];
  label?: Maybe<Scalars['String']>;
  legalName: Scalars['String'];
  mainContact?: Maybe<UserType>;
  name: Scalars['String'];
  region?: Maybe<RegionType>;
  supervisors: Array<UserType>;
  users: Array<UserType>;
};

export type PaymentMethodDiscountPerCategoryType = {
  __typename?: 'PaymentMethodDiscountPerCategoryType';
  category: CategoryType;
  discount: Scalars['Float'];
  helpText: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  paymentMethod: PaymentMethodType;
};

export type PaymentMethodType = {
  __typename?: 'PaymentMethodType';
  allowsFinancing: Scalars['Boolean'];
  defaultInterestRate: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  discount: Scalars['Float'];
  discountsPerCategory: Array<PaymentMethodDiscountPerCategoryType>;
  id: Scalars['Int'];
  name: Scalars['String'];
  order: Scalars['Int'];
  quoteSet: Array<QuoteType>;
};

export type PrivateMutation = {
  __typename?: 'PrivateMutation';
  createQuote?: Maybe<CreateQuote>;
  createRecommendation?: Maybe<CreateRecommendation>;
  resendQuote?: Maybe<ResendQuote>;
  resendRecommendation?: Maybe<RessendRecommendation>;
  upsertContact?: Maybe<UpsertContact>;
};


export type PrivateMutationCreateQuoteArgs = {
  contact?: InputMaybe<Scalars['Int']>;
  event?: InputMaybe<Scalars['Int']>;
  expirationDate?: InputMaybe<Scalars['String']>;
  isComparison: Scalars['Boolean'];
  items?: InputMaybe<Array<InputMaybe<QuoteItemInput>>>;
  notes?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['Int']>;
  paymentMethods?: InputMaybe<Array<InputMaybe<QuotePaymentMethodInput>>>;
  pdfPaymentMethodDetail: Scalars['Boolean'];
  productsTechnicalInfo?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  status?: InputMaybe<Scalars['String']>;
};


export type PrivateMutationCreateRecommendationArgs = {
  contact?: InputMaybe<Scalars['Int']>;
  event?: InputMaybe<Scalars['Int']>;
  notes?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['Int']>;
  products?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  seller?: InputMaybe<Scalars['String']>;
};


export type PrivateMutationResendQuoteArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type PrivateMutationResendRecommendationArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type PrivateMutationUpsertContactArgs = {
  account?: InputMaybe<Scalars['ID']>;
  contact?: InputMaybe<Scalars['ID']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  taxId?: InputMaybe<Scalars['String']>;
};

export type PrivateQuery = {
  __typename?: 'PrivateQuery';
  comboDiscounts?: Maybe<Array<Maybe<ComboDiscountType>>>;
  comparisonList?: Maybe<Array<Maybe<QuoteType>>>;
  contacts?: Maybe<Array<Maybe<ContactOptionType>>>;
  currentEvents?: Maybe<Array<Maybe<EventType>>>;
  discountLimits?: Maybe<Array<Maybe<DiscountLimitType>>>;
  events?: Maybe<Array<Maybe<EventType>>>;
  locations?: Maybe<Array<Maybe<LocationType>>>;
  organizations?: Maybe<Array<Maybe<OrganizationType>>>;
  paymentMethods?: Maybe<Array<Maybe<PaymentMethodType>>>;
  productBands?: Maybe<Array<Maybe<BandType>>>;
  productCategories?: Maybe<Array<Maybe<CategoryType>>>;
  productsForQuote?: Maybe<Array<Maybe<ProductType>>>;
  productsForRecommendation?: Maybe<Array<Maybe<ProductType>>>;
  quote?: Maybe<QuoteType>;
  quoteExpirationTimeLimit?: Maybe<QuoteExpirationTimeLimitType>;
  quoteList?: Maybe<Array<Maybe<QuoteType>>>;
  quotesAnalytics?: Maybe<QuotesAnalyticsType>;
  recommendationList?: Maybe<Array<Maybe<RecommendationType>>>;
  recommendationsAnalytics?: Maybe<RecommendationsAnalyticsType>;
  user?: Maybe<UserType>;
  users?: Maybe<Array<Maybe<UserType>>>;
  usersCategories?: Maybe<Array<Maybe<UserCategoryType>>>;
};


export type PrivateQueryComparisonListArgs = {
  filters?: InputMaybe<QuotesFiltersInputType>;
};


export type PrivateQueryContactsArgs = {
  id?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
};


export type PrivateQueryLocationsArgs = {
  id?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
};


export type PrivateQueryOrganizationsArgs = {
  id?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
};


export type PrivateQueryQuoteArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type PrivateQueryQuoteListArgs = {
  filters?: InputMaybe<QuotesFiltersInputType>;
};


export type PrivateQueryQuotesAnalyticsArgs = {
  filters: AnalyticsFilters;
};


export type PrivateQueryRecommendationListArgs = {
  filters?: InputMaybe<RecommendationsFiltersInputType>;
};


export type PrivateQueryRecommendationsAnalyticsArgs = {
  filters: AnalyticsFilters;
};


export type PrivateQueryUsersArgs = {
  organization?: InputMaybe<Scalars['Int']>;
  userCategory?: InputMaybe<Scalars['String']>;
};

export type ProductRankingDatum = {
  __typename?: 'ProductRankingDatum';
  amount?: Maybe<Scalars['Float']>;
  category: Scalars['String'];
  count: Scalars['Int'];
  product: Scalars['String'];
};

export type ProductType = {
  __typename?: 'ProductType';
  bands?: Maybe<Array<Maybe<BandType>>>;
  category: CategoryType;
  created: Scalars['DateTime'];
  id: Scalars['Int'];
  modified: Scalars['DateTime'];
  name: Scalars['String'];
  order: Scalars['Int'];
  price: Scalars['Float'];
  publishedForQuote: Scalars['Boolean'];
  publishedForRecommendation: Scalars['Boolean'];
  quoteitemSet: Array<QuoteItemType>;
  recommendations: Array<RecommendationType>;
  unit: UnitType;
};

export type ProductsByCategoryType = {
  __typename?: 'ProductsByCategoryType';
  category?: Maybe<CategoryType>;
  products?: Maybe<Array<Maybe<ProductType>>>;
};

export type QuoteExpirationTimeLimitType = {
  __typename?: 'QuoteExpirationTimeLimitType';
  id: Scalars['ID'];
  maximumDays: Scalars['Int'];
  minimumDays: Scalars['Int'];
};

export type QuoteItemInput = {
  band?: InputMaybe<Scalars['Int']>;
  comboDiscounts?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  comboTypes?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  nideraDiscount?: InputMaybe<Scalars['Float']>;
  organizationDiscount?: InputMaybe<Scalars['Float']>;
  product?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['Int']>;
  totalComboDiscount?: InputMaybe<Scalars['Float']>;
  volumeDiscount?: InputMaybe<Scalars['Float']>;
};

export type QuoteItemType = {
  __typename?: 'QuoteItemType';
  band?: Maybe<BandType>;
  bandDiscount: Scalars['Float'];
  category?: Maybe<CategoryType>;
  comboDiscounts: Array<ComboDiscountType>;
  comboTypes: Array<ComboTypeType>;
  denormalized?: Maybe<Scalars['JSONString']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  nideraDiscount: Scalars['Float'];
  organizationDiscount: Scalars['Float'];
  price: Scalars['Float'];
  product?: Maybe<ProductType>;
  quantity: Scalars['Int'];
  quote: QuoteType;
  totalComboDiscount: Scalars['Float'];
  unit?: Maybe<UnitType>;
  volumeDiscount: Scalars['Float'];
};

export type QuotePaymentMethodInput = {
  financingMonths?: InputMaybe<Scalars['Int']>;
  interestRate?: InputMaybe<Scalars['Float']>;
  paymentMethod?: InputMaybe<Scalars['Int']>;
};

export type QuotePaymentMethodType = {
  __typename?: 'QuotePaymentMethodType';
  discount: Scalars['Float'];
  financingMonths: Scalars['Int'];
  id: Scalars['Int'];
  interestRate: Scalars['Float'];
  name: Scalars['String'];
  paymentMethod?: Maybe<PaymentMethodType>;
  quote: QuoteType;
};

export type QuoteType = {
  __typename?: 'QuoteType';
  contact: ContactType;
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  event?: Maybe<EventType>;
  expirationDate: Scalars['Date'];
  file?: Maybe<Scalars['String']>;
  fileTechnicalInfo?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  includeTechnicalInfo: Scalars['Boolean'];
  isComparison: Scalars['Boolean'];
  items: Array<QuoteItemType>;
  modified: Scalars['DateTime'];
  notes: Scalars['String'];
  paymentMethods: Array<PaymentMethodType>;
  pdfPaymentMethodDetail: Scalars['Boolean'];
  productsTechnicalInfo?: Maybe<Array<Maybe<Scalars['String']>>>;
  quotePaymentMethods: Array<QuotePaymentMethodType>;
  replyTo?: Maybe<UserType>;
  shareUrl?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  status: QuotesQuoteStatusChoices;
  statusDisplay?: Maybe<Scalars['String']>;
};

export type QuotesAnalyticsType = {
  __typename?: 'QuotesAnalyticsType';
  amount: Scalars['Float'];
  byCategory: Array<CategoriesDatum>;
  byDate: Array<DateSeriesDatum>;
  byPaymentMethod: Array<AmountDatum>;
  count: Scalars['Int'];
  productRanking: Array<ProductRankingDatum>;
};

export type QuotesFiltersInputType = {
  account?: InputMaybe<Scalars['Int']>;
  contact?: InputMaybe<Scalars['Int']>;
  dateFrom?: InputMaybe<Scalars['Date']>;
  dateTo?: InputMaybe<Scalars['Date']>;
  orderBy?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

export enum QuotesQuoteStatusChoices {
  Expired = 'EXPIRED',
  Valid = 'VALID'
}

export type RecommendationType = {
  __typename?: 'RecommendationType';
  contact: ContactType;
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  denormalized?: Maybe<Scalars['JSONString']>;
  event?: Maybe<EventType>;
  file?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  modified: Scalars['DateTime'];
  notes: Scalars['String'];
  products: Array<ProductType>;
  productsByCategory?: Maybe<Array<Maybe<ProductsByCategoryType>>>;
  replyTo?: Maybe<UserType>;
  seller?: Maybe<Scalars['String']>;
  shareUrl?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
};

export type RecommendationsAnalyticsType = {
  __typename?: 'RecommendationsAnalyticsType';
  byCategory: Array<CategoriesDatum>;
  byDate: Array<DateSeriesDatum>;
  count: Scalars['Int'];
  productRanking: Array<ProductRankingDatum>;
  productsAverage: Scalars['Float'];
};

export type RecommendationsFiltersInputType = {
  account?: InputMaybe<Scalars['Int']>;
  contact?: InputMaybe<Scalars['Int']>;
  dateFrom?: InputMaybe<Scalars['Date']>;
  dateTo?: InputMaybe<Scalars['Date']>;
  orderBy?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

export type RegionType = {
  __typename?: 'RegionType';
  id: Scalars['Int'];
  name: Scalars['String'];
  organizationSet: Array<OrganizationType>;
};

export type ResendQuote = {
  __typename?: 'ResendQuote';
  errors?: Maybe<Scalars['GenericScalar']>;
  success: Scalars['Boolean'];
};

export type RessendRecommendation = {
  __typename?: 'RessendRecommendation';
  errors?: Maybe<Scalars['GenericScalar']>;
  success: Scalars['Boolean'];
};

export type UnitType = {
  __typename?: 'UnitType';
  codename: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  namePlural: Scalars['String'];
  products: Array<ProductType>;
  quoteitemSet: Array<QuoteItemType>;
};

export type UpsertContact = {
  __typename?: 'UpsertContact';
  contact?: Maybe<ContactType>;
  errors?: Maybe<Scalars['GenericScalar']>;
  success: Scalars['Boolean'];
};

export type UserCategoryType = {
  __typename?: 'UserCategoryType';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type UserPermissionsType = {
  __typename?: 'UserPermissionsType';
  canCreateQuote: Scalars['Boolean'];
  canCreateRecommendation: Scalars['Boolean'];
  canViewQuote: Scalars['Boolean'];
  canViewRecommendation: Scalars['Boolean'];
  canViewStatistics: Scalars['Boolean'];
};

export type UserType = {
  __typename?: 'UserType';
  accounts: Array<AccountType>;
  category?: Maybe<UserCategoryType>;
  configured: Scalars['Boolean'];
  contacts: Array<ContactType>;
  dateJoined: Scalars['DateTime'];
  email: Scalars['String'];
  externalId?: Maybe<Scalars['UUID']>;
  firstName: Scalars['String'];
  fullName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isActive: Scalars['Boolean'];
  isStaff: Scalars['Boolean'];
  isSuperuser: Scalars['Boolean'];
  lastAccess?: Maybe<Scalars['DateTime']>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  lastName: Scalars['String'];
  organization?: Maybe<OrganizationType>;
  organizations: Array<OrganizationType>;
  password: Scalars['String'];
  permissions?: Maybe<UserPermissionsType>;
  quoteSet: Array<QuoteType>;
  quotes: Array<QuoteType>;
  recommendationSet: Array<RecommendationType>;
  recommendations: Array<RecommendationType>;
  supervisedOrganizations: Array<OrganizationType>;
  username: Scalars['String'];
};

export type UserFragment = { __typename?: 'UserType', id: number, email: string, fullName?: string, firstName: string, lastName: string, isStaff: boolean, category?: { __typename?: 'UserCategoryType', id?: string, name?: string }, organization?: { __typename?: 'OrganizationType', id: number, name: string, legalName: string, cuit: string, isActive: boolean, region?: { __typename?: 'RegionType', id: number, name: string } }, permissions?: { __typename?: 'UserPermissionsType', canViewStatistics: boolean, canViewQuote: boolean, canViewRecommendation: boolean, canCreateQuote: boolean, canCreateRecommendation: boolean } };

export type UserCategoryFragment = { __typename?: 'UserCategoryType', id?: string, name?: string };

export type GetUsersCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUsersCategoriesQuery = { __typename?: 'PrivateQuery', usersCategories?: Array<{ __typename?: 'UserCategoryType', id?: string, name?: string }> };

export type UserOrganizationFragment = { __typename?: 'OrganizationType', id: number, name: string, legalName: string, cuit: string, isActive: boolean, region?: { __typename?: 'RegionType', id: number, name: string } };

export type RegionFragment = { __typename?: 'RegionType', id: number, name: string };

export type ContactOptionFragment = { __typename?: 'ContactType', id: number, displayName?: string };

export type GetUsersQueryVariables = Exact<{
  organization?: InputMaybe<Scalars['Int']>;
  userCategory?: InputMaybe<Scalars['String']>;
}>;


export type GetUsersQuery = { __typename?: 'PrivateQuery', users?: Array<{ __typename?: 'UserType', id: number, email: string, fullName?: string, firstName: string, lastName: string, isStaff: boolean, category?: { __typename?: 'UserCategoryType', id?: string, name?: string }, organization?: { __typename?: 'OrganizationType', id: number, name: string, legalName: string, cuit: string, isActive: boolean, region?: { __typename?: 'RegionType', id: number, name: string } }, permissions?: { __typename?: 'UserPermissionsType', canViewStatistics: boolean, canViewQuote: boolean, canViewRecommendation: boolean, canCreateQuote: boolean, canCreateRecommendation: boolean } }> };

export type GetUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserQuery = { __typename?: 'PrivateQuery', user?: { __typename?: 'UserType', id: number, email: string, fullName?: string, firstName: string, lastName: string, isStaff: boolean, category?: { __typename?: 'UserCategoryType', id?: string, name?: string }, organization?: { __typename?: 'OrganizationType', id: number, name: string, legalName: string, cuit: string, isActive: boolean, region?: { __typename?: 'RegionType', id: number, name: string } }, permissions?: { __typename?: 'UserPermissionsType', canViewStatistics: boolean, canViewQuote: boolean, canViewRecommendation: boolean, canCreateQuote: boolean, canCreateRecommendation: boolean } } };

export type GetEventQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEventQuery = { __typename?: 'PrivateQuery', events?: Array<{ __typename?: 'EventType', id: number, name: string, startDate: string, endDate: string }> };

export type GetAutoCompleteContactsFilterQueryVariables = Exact<{
  query?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
}>;


export type GetAutoCompleteContactsFilterQuery = { __typename?: 'PrivateQuery', options?: Array<{ __typename?: 'ContactOptionType', label?: string, account?: { __typename?: 'AccountType', id: number }, contact?: { __typename?: 'ContactType', id: number } }> };

export type ContactOrganizationsFragment = { __typename?: 'OrganizationType', id: number, name: string };

export type AutocompleteContactFragment = { __typename?: 'ContactOptionType', name?: string, taxId?: string, label?: string, email?: string, firstName?: string, lastName?: string, phone?: string, account?: { __typename?: 'AccountType', id: number }, locations?: Array<{ __typename?: 'LocationType', id: number, path?: string }>, organizations?: Array<{ __typename?: 'OrganizationType', id: number, name: string }>, contact?: { __typename?: 'ContactType', id: number } };

export type GetAutoCompleteContactsQueryVariables = Exact<{
  query?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
}>;


export type GetAutoCompleteContactsQuery = { __typename?: 'PrivateQuery', contacts?: Array<{ __typename?: 'ContactOptionType', name?: string, taxId?: string, label?: string, email?: string, firstName?: string, lastName?: string, phone?: string, account?: { __typename?: 'AccountType', id: number }, locations?: Array<{ __typename?: 'LocationType', id: number, path?: string }>, organizations?: Array<{ __typename?: 'OrganizationType', id: number, name: string }>, contact?: { __typename?: 'ContactType', id: number } }> };

export type AutocompleteOrganizationsFragment = { __typename?: 'OrganizationType', id: number, label?: string };

export type GetAutoCompleteOrganizationsQueryVariables = Exact<{
  query?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
}>;


export type GetAutoCompleteOrganizationsQuery = { __typename?: 'PrivateQuery', organizations?: Array<{ __typename?: 'OrganizationType', id: number, label?: string }> };

export type AutoCompleteLocationsFragment = { __typename?: 'LocationType', id: number, path?: string };

export type GetAutoCompleteLocationsQueryVariables = Exact<{
  query?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
}>;


export type GetAutoCompleteLocationsQuery = { __typename?: 'PrivateQuery', locations?: Array<{ __typename?: 'LocationType', id: number, path?: string }> };

export type ComboTypeFragment = { __typename?: 'ComboTypeType', id: number, name: string };

export type GetComboDiscountsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetComboDiscountsQuery = { __typename?: 'PrivateQuery', comboDiscounts?: Array<{ __typename?: 'ComboDiscountType', id: number, discount1: number, discount2: number, type?: { __typename?: 'ComboTypeType', id: number, name: string }, products1: Array<{ __typename?: 'ProductType', id: number }>, products2: Array<{ __typename?: 'ProductType', id: number }> }> };

export type GetComparisonListQueryVariables = Exact<{
  filters?: InputMaybe<QuotesFiltersInputType>;
}>;


export type GetComparisonListQuery = { __typename?: 'PrivateQuery', list?: Array<{ __typename?: 'QuoteType', id: number, shareUrl?: string, created: string, contact: { __typename?: 'ContactType', email: string, displayName?: string, account: { __typename?: 'AccountType', taxId: string } }, paymentMethods: Array<{ __typename?: 'PaymentMethodType', name: string }>, items: Array<{ __typename?: 'QuoteItemType', name: string, quantity: number, band?: { __typename?: 'BandType', name: string } }> }> };

export type GetCurrentEventsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentEventsQuery = { __typename?: 'PrivateQuery', currentEvents?: Array<{ __typename?: 'EventType', id: number, name: string }> };

export type GetEventsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEventsQuery = { __typename?: 'PrivateQuery', events?: Array<{ __typename?: 'EventType', id: number, name: string }> };

export type DiscountLimitsFragment = { __typename?: 'DiscountLimitType', id: number, name: string, codeName: string, maxRate: number };

export type GetDiscountLimitsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDiscountLimitsQuery = { __typename?: 'PrivateQuery', discountLimits?: Array<{ __typename?: 'DiscountLimitType', id: number, name: string, codeName: string, maxRate: number }> };

export type DiscountsPerCategoryFragment = { __typename?: 'PaymentMethodDiscountPerCategoryType', helpText: string, discount: number, category: { __typename?: 'CategoryType', id: number } };

export type PaymentMethodsFragment = { __typename?: 'PaymentMethodType', id: number, name: string, allowsFinancing: boolean, defaultInterestRate: number, discount: number, discountsPerCategory: Array<{ __typename?: 'PaymentMethodDiscountPerCategoryType', helpText: string, discount: number, category: { __typename?: 'CategoryType', id: number } }> };

export type CategoryFragment = { __typename?: 'CategoryType', id: number, name: string };

export type GetPaymentMethodsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPaymentMethodsQuery = { __typename?: 'PrivateQuery', paymentMethods?: Array<{ __typename?: 'PaymentMethodType', id: number, name: string, allowsFinancing: boolean, defaultInterestRate: number, discount: number, discountsPerCategory: Array<{ __typename?: 'PaymentMethodDiscountPerCategoryType', helpText: string, discount: number, category: { __typename?: 'CategoryType', id: number } }> }> };

export type GetProductCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProductCategoriesQuery = { __typename?: 'PrivateQuery', productCategories?: Array<{ __typename?: 'CategoryType', id: number, name: string }> };

export type BandsFragment = { __typename?: 'BandType', id: number, name: string };

export type UnitFragment = { __typename?: 'UnitType', namePlural: string };

export type ProductsForQuoteFragment = { __typename?: 'ProductType', id: number, name: string, price: number };

export type ProductBandsFragment = { __typename?: 'BandType', id: number, name: string, discount: number, category: { __typename?: 'CategoryType', id: number } };

export type GetProductsForQuoteQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProductsForQuoteQuery = { __typename?: 'PrivateQuery', productsForQuote?: Array<{ __typename?: 'ProductType', id: number, name: string, price: number, category: { __typename?: 'CategoryType', id: number, name: string }, bands?: Array<{ __typename?: 'BandType', id: number, name: string }>, unit: { __typename?: 'UnitType', namePlural: string } }>, productBands?: Array<{ __typename?: 'BandType', id: number, name: string, discount: number, category: { __typename?: 'CategoryType', id: number } }> };

export type GetProductsForRecommendationQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProductsForRecommendationQuery = { __typename?: 'PrivateQuery', productsForRecommendation?: Array<{ __typename?: 'ProductType', id: number, name: string, category: { __typename?: 'CategoryType', id: number, name: string } }> };

export type GetQuoteExpirationTimeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetQuoteExpirationTimeQuery = { __typename?: 'PrivateQuery', quoteExpirationTimeLimit?: { __typename?: 'QuoteExpirationTimeLimitType', minimumDays: number, maximumDays: number } };

export type GetQuoteListQueryVariables = Exact<{
  filters?: InputMaybe<QuotesFiltersInputType>;
}>;


export type GetQuoteListQuery = { __typename?: 'PrivateQuery', list?: Array<{ __typename?: 'QuoteType', id: number, shareUrl?: string, created: string, status: QuotesQuoteStatusChoices, statusDisplay?: string, expirationDate: string, contact: { __typename?: 'ContactType', email: string, phone: string, displayName?: string, account: { __typename?: 'AccountType', taxId: string, name: string } }, paymentMethods: Array<{ __typename?: 'PaymentMethodType', name: string }>, items: Array<{ __typename?: 'QuoteItemType', name: string, quantity: number, band?: { __typename?: 'BandType', name: string } }> }> };

export type QuotePaymentMethodsFragment = { __typename?: 'QuotePaymentMethodType', id: number, name: string, discount: number, financingMonths: number, interestRate: number, paymentMethod?: { __typename?: 'PaymentMethodType', id: number, allowsFinancing: boolean, discountsPerCategory: Array<{ __typename?: 'PaymentMethodDiscountPerCategoryType', helpText: string, discount: number, category: { __typename?: 'CategoryType', id: number } }> } };

export type GetQuoteQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetQuoteQuery = { __typename?: 'PrivateQuery', quote?: { __typename?: 'QuoteType', id: number, shareUrl?: string, created: string, status: QuotesQuoteStatusChoices, statusDisplay?: string, expirationDate: string, contact: { __typename?: 'ContactType', email: string, phone: string, displayName?: string, account: { __typename?: 'AccountType', taxId: string } }, quotePaymentMethods: Array<{ __typename?: 'QuotePaymentMethodType', id: number, name: string, discount: number, financingMonths: number, interestRate: number, paymentMethod?: { __typename?: 'PaymentMethodType', id: number, allowsFinancing: boolean, discountsPerCategory: Array<{ __typename?: 'PaymentMethodDiscountPerCategoryType', helpText: string, discount: number, category: { __typename?: 'CategoryType', id: number } }> } }>, items: Array<{ __typename?: 'QuoteItemType', name: string, price: number, quantity: number, bandDiscount: number, volumeDiscount: number, nideraDiscount: number, organizationDiscount: number, totalComboDiscount: number, category?: { __typename?: 'CategoryType', id: number, name: string, bands: Array<{ __typename?: 'BandType', id: number, name: string, discount: number, category: { __typename?: 'CategoryType', id: number } }> }, product?: { __typename?: 'ProductType', id: number, unit: { __typename?: 'UnitType', namePlural: string } }, band?: { __typename?: 'BandType', discount: number, id: number } }> } };

export type GetRecommendationListQueryVariables = Exact<{
  filters?: InputMaybe<RecommendationsFiltersInputType>;
}>;


export type GetRecommendationListQuery = { __typename?: 'PrivateQuery', list?: Array<{ __typename?: 'RecommendationType', id: number, created: string, shareUrl?: string, contact: { __typename?: 'ContactType', email: string, phone: string, displayName?: string, account: { __typename?: 'AccountType', taxId: string } }, productsByCategory?: Array<{ __typename?: 'ProductsByCategoryType', category?: { __typename?: 'CategoryType', name: string }, products?: Array<{ __typename?: 'ProductType', name: string }> }> }> };

export type QuotesAnalyticsFragment = { __typename?: 'QuotesAnalyticsType', count: number, amount: number, productRanking: Array<{ __typename?: 'ProductRankingDatum', product: string, category: string, count: number, amount?: number }>, byDate: Array<{ __typename?: 'DateSeriesDatum', date: string, count: number, amount?: number, groupBy?: string }>, byCategory: Array<{ __typename?: 'CategoriesDatum', categoryId: number, category: string, count: number, amount?: number }>, byPaymentMethod: Array<{ __typename?: 'AmountDatum', label: string, count: number, amount: number, percentage?: number }> };

export type RecommendationsAnalyticsFragment = { __typename?: 'RecommendationsAnalyticsType', count: number, productsAverage: number, productRanking: Array<{ __typename?: 'ProductRankingDatum', product: string, category: string, count: number }>, byDate: Array<{ __typename?: 'DateSeriesDatum', date: string, count: number, groupBy?: string }>, byCategory: Array<{ __typename?: 'CategoriesDatum', categoryId: number, category: string, count: number }> };

export type GetQuotesAnalyticsQueryVariables = Exact<{
  filters: AnalyticsFilters;
}>;


export type GetQuotesAnalyticsQuery = { __typename?: 'PrivateQuery', quotesAnalytics?: { __typename?: 'QuotesAnalyticsType', count: number, amount: number, productRanking: Array<{ __typename?: 'ProductRankingDatum', product: string, category: string, count: number, amount?: number }>, byDate: Array<{ __typename?: 'DateSeriesDatum', date: string, count: number, amount?: number, groupBy?: string }>, byCategory: Array<{ __typename?: 'CategoriesDatum', categoryId: number, category: string, count: number, amount?: number }>, byPaymentMethod: Array<{ __typename?: 'AmountDatum', label: string, count: number, amount: number, percentage?: number }> } };

export type GetRecommendationsAnalyticsQueryVariables = Exact<{
  filters: AnalyticsFilters;
}>;


export type GetRecommendationsAnalyticsQuery = { __typename?: 'PrivateQuery', recommendationsAnalytics?: { __typename?: 'RecommendationsAnalyticsType', count: number, productsAverage: number, productRanking: Array<{ __typename?: 'ProductRankingDatum', product: string, category: string, count: number }>, byDate: Array<{ __typename?: 'DateSeriesDatum', date: string, count: number, groupBy?: string }>, byCategory: Array<{ __typename?: 'CategoriesDatum', categoryId: number, category: string, count: number }> } };

export type AccountFragment = { __typename?: 'AccountType', id: number };

export type ContactFragment = { __typename?: 'ContactType', firstName: string, lastName: string, email: string, phone: string, displayName?: string, account: { __typename?: 'AccountType', id: number } };

export type UpsertContactMutationVariables = Exact<{
  accountId?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  taxId: Scalars['String'];
  locations: Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>;
  contactId?: InputMaybe<Scalars['ID']>;
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
}>;


export type UpsertContactMutation = { __typename?: 'PrivateMutation', upsertContact?: { __typename?: 'UpsertContact', success: boolean, errors?: any, contact?: { __typename?: 'ContactType', id: number } } };

export type CreateQuoteMutationVariables = Exact<{
  contact: Scalars['Int'];
  paymentMethods: Array<InputMaybe<QuotePaymentMethodInput>> | InputMaybe<QuotePaymentMethodInput>;
  notes?: InputMaybe<Scalars['String']>;
  items: Array<InputMaybe<QuoteItemInput>> | InputMaybe<QuoteItemInput>;
  organization?: InputMaybe<Scalars['Int']>;
  event?: InputMaybe<Scalars['Int']>;
  isComparison: Scalars['Boolean'];
  productsTechnicalInfo?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  status?: InputMaybe<Scalars['String']>;
  expirationDate?: InputMaybe<Scalars['String']>;
  pdfPaymentMethodDetail: Scalars['Boolean'];
}>;


export type CreateQuoteMutation = { __typename?: 'PrivateMutation', createQuote?: { __typename?: 'CreateQuote', success: boolean, errors?: any, quote?: { __typename?: 'QuoteType', shareUrl?: string, id: number, contact: { __typename?: 'ContactType', phone: string } } } };

export type CreateRecommendationMutationVariables = Exact<{
  contact: Scalars['Int'];
  products: Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>;
  notes?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['Int']>;
  event?: InputMaybe<Scalars['Int']>;
  seller?: InputMaybe<Scalars['String']>;
}>;


export type CreateRecommendationMutation = { __typename?: 'PrivateMutation', createRecommendation?: { __typename?: 'CreateRecommendation', success: boolean, errors?: any, recommendation?: { __typename?: 'RecommendationType', shareUrl?: string, contact: { __typename?: 'ContactType', phone: string } } } };

export type ResendQuoteMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ResendQuoteMutation = { __typename?: 'PrivateMutation', resendQuote?: { __typename?: 'ResendQuote', success: boolean, errors?: any } };

export type ResendRecommendationMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ResendRecommendationMutation = { __typename?: 'PrivateMutation', resendRecommendation?: { __typename?: 'RessendRecommendation', success: boolean, errors?: any } };

export const UserCategoryFragmentDoc = gql`
    fragment UserCategory on UserCategoryType {
  id
  name
}
    `;
export const RegionFragmentDoc = gql`
    fragment Region on RegionType {
  id
  name
}
    `;
export const UserOrganizationFragmentDoc = gql`
    fragment UserOrganization on OrganizationType {
  id
  name
  legalName
  cuit
  isActive
  region {
    ...Region
  }
}
    ${RegionFragmentDoc}`;
export const UserFragmentDoc = gql`
    fragment User on UserType {
  id
  email
  fullName
  firstName
  lastName
  isStaff
  category {
    ...UserCategory
  }
  organization {
    ...UserOrganization
  }
  permissions {
    canViewStatistics
    canViewQuote
    canViewRecommendation
    canCreateQuote
    canCreateRecommendation
  }
}
    ${UserCategoryFragmentDoc}
${UserOrganizationFragmentDoc}`;
export const ContactOptionFragmentDoc = gql`
    fragment ContactOption on ContactType {
  id
  displayName
}
    `;
export const ContactOrganizationsFragmentDoc = gql`
    fragment ContactOrganizations on OrganizationType {
  id
  name
}
    `;
export const AutocompleteContactFragmentDoc = gql`
    fragment AutocompleteContact on ContactOptionType {
  account {
    id
  }
  name
  taxId
  locations {
    id
    path
  }
  organizations {
    ...ContactOrganizations
  }
  label
  contact {
    id
  }
  email
  firstName
  lastName
  phone
}
    ${ContactOrganizationsFragmentDoc}`;
export const AutocompleteOrganizationsFragmentDoc = gql`
    fragment AutocompleteOrganizations on OrganizationType {
  id
  label
}
    `;
export const AutoCompleteLocationsFragmentDoc = gql`
    fragment AutoCompleteLocations on LocationType {
  id
  path
}
    `;
export const ComboTypeFragmentDoc = gql`
    fragment ComboType on ComboTypeType {
  id
  name
}
    `;
export const DiscountLimitsFragmentDoc = gql`
    fragment DiscountLimits on DiscountLimitType {
  id
  name
  codeName
  maxRate
}
    `;
export const DiscountsPerCategoryFragmentDoc = gql`
    fragment DiscountsPerCategory on PaymentMethodDiscountPerCategoryType {
  helpText
  discount
  category {
    id
  }
}
    `;
export const PaymentMethodsFragmentDoc = gql`
    fragment PaymentMethods on PaymentMethodType {
  id
  name
  allowsFinancing
  defaultInterestRate
  discount
  discountsPerCategory {
    ...DiscountsPerCategory
  }
}
    ${DiscountsPerCategoryFragmentDoc}`;
export const CategoryFragmentDoc = gql`
    fragment Category on CategoryType {
  id
  name
}
    `;
export const BandsFragmentDoc = gql`
    fragment Bands on BandType {
  id
  name
}
    `;
export const UnitFragmentDoc = gql`
    fragment Unit on UnitType {
  namePlural
}
    `;
export const ProductsForQuoteFragmentDoc = gql`
    fragment ProductsForQuote on ProductType {
  id
  name
  price
}
    `;
export const ProductBandsFragmentDoc = gql`
    fragment ProductBands on BandType {
  id
  name
  discount
  category {
    id
  }
}
    `;
export const QuotePaymentMethodsFragmentDoc = gql`
    fragment QuotePaymentMethods on QuotePaymentMethodType {
  id
  name
  discount
  financingMonths
  interestRate
  paymentMethod {
    id
    allowsFinancing
    discountsPerCategory {
      helpText
      discount
      category {
        id
      }
    }
  }
}
    `;
export const QuotesAnalyticsFragmentDoc = gql`
    fragment QuotesAnalytics on QuotesAnalyticsType {
  count
  amount
  productRanking {
    product
    category
    count
    amount
  }
  byDate {
    date
    count
    amount
    groupBy
  }
  byCategory {
    categoryId
    category
    count
    amount
  }
  byPaymentMethod {
    label
    count
    amount
    percentage
  }
}
    `;
export const RecommendationsAnalyticsFragmentDoc = gql`
    fragment RecommendationsAnalytics on RecommendationsAnalyticsType {
  count
  productsAverage
  productRanking {
    product
    category
    count
  }
  byDate {
    date
    count
    groupBy
  }
  byCategory {
    categoryId
    category
    count
  }
}
    `;
export const AccountFragmentDoc = gql`
    fragment Account on AccountType {
  id
}
    `;
export const ContactFragmentDoc = gql`
    fragment Contact on ContactType {
  account {
    ...Account
  }
  firstName
  lastName
  email
  phone
  displayName
}
    ${AccountFragmentDoc}`;
export const GetUsersCategoriesDocument = gql`
    query getUsersCategories {
  usersCategories {
    ...UserCategory
  }
}
    ${UserCategoryFragmentDoc}`;

/**
 * __useGetUsersCategoriesQuery__
 *
 * To run a query within a React component, call `useGetUsersCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsersCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersCategoriesQuery, GetUsersCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersCategoriesQuery, GetUsersCategoriesQueryVariables>(GetUsersCategoriesDocument, options);
      }
export function useGetUsersCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersCategoriesQuery, GetUsersCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersCategoriesQuery, GetUsersCategoriesQueryVariables>(GetUsersCategoriesDocument, options);
        }
export type GetUsersCategoriesQueryHookResult = ReturnType<typeof useGetUsersCategoriesQuery>;
export type GetUsersCategoriesLazyQueryHookResult = ReturnType<typeof useGetUsersCategoriesLazyQuery>;
export type GetUsersCategoriesQueryResult = Apollo.QueryResult<GetUsersCategoriesQuery, GetUsersCategoriesQueryVariables>;
export function refetchGetUsersCategoriesQuery(variables?: GetUsersCategoriesQueryVariables) {
      return { query: GetUsersCategoriesDocument, variables: variables }
    }
export const GetUsersDocument = gql`
    query getUsers($organization: Int, $userCategory: String) {
  users(organization: $organization, userCategory: $userCategory) {
    ...User
  }
}
    ${UserFragmentDoc}`;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *      organization: // value for 'organization'
 *      userCategory: // value for 'userCategory'
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
      }
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export function refetchGetUsersQuery(variables?: GetUsersQueryVariables) {
      return { query: GetUsersDocument, variables: variables }
    }
export const GetUserDocument = gql`
    query getUser {
  user {
    ...User
  }
}
    ${UserFragmentDoc}`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserQuery(baseOptions?: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export function refetchGetUserQuery(variables?: GetUserQueryVariables) {
      return { query: GetUserDocument, variables: variables }
    }
export const GetEventDocument = gql`
    query getEvent {
  events {
    id
    name
    startDate
    endDate
  }
}
    `;

/**
 * __useGetEventQuery__
 *
 * To run a query within a React component, call `useGetEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEventQuery(baseOptions?: Apollo.QueryHookOptions<GetEventQuery, GetEventQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEventQuery, GetEventQueryVariables>(GetEventDocument, options);
      }
export function useGetEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEventQuery, GetEventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEventQuery, GetEventQueryVariables>(GetEventDocument, options);
        }
export type GetEventQueryHookResult = ReturnType<typeof useGetEventQuery>;
export type GetEventLazyQueryHookResult = ReturnType<typeof useGetEventLazyQuery>;
export type GetEventQueryResult = Apollo.QueryResult<GetEventQuery, GetEventQueryVariables>;
export function refetchGetEventQuery(variables?: GetEventQueryVariables) {
      return { query: GetEventDocument, variables: variables }
    }
export const GetAutoCompleteContactsFilterDocument = gql`
    query getAutoCompleteContactsFilter($query: String, $id: Int) {
  options: contacts(query: $query, id: $id) {
    account {
      id
    }
    contact {
      id
    }
    label: filterLabel
  }
}
    `;

/**
 * __useGetAutoCompleteContactsFilterQuery__
 *
 * To run a query within a React component, call `useGetAutoCompleteContactsFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAutoCompleteContactsFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAutoCompleteContactsFilterQuery({
 *   variables: {
 *      query: // value for 'query'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAutoCompleteContactsFilterQuery(baseOptions?: Apollo.QueryHookOptions<GetAutoCompleteContactsFilterQuery, GetAutoCompleteContactsFilterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAutoCompleteContactsFilterQuery, GetAutoCompleteContactsFilterQueryVariables>(GetAutoCompleteContactsFilterDocument, options);
      }
export function useGetAutoCompleteContactsFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAutoCompleteContactsFilterQuery, GetAutoCompleteContactsFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAutoCompleteContactsFilterQuery, GetAutoCompleteContactsFilterQueryVariables>(GetAutoCompleteContactsFilterDocument, options);
        }
export type GetAutoCompleteContactsFilterQueryHookResult = ReturnType<typeof useGetAutoCompleteContactsFilterQuery>;
export type GetAutoCompleteContactsFilterLazyQueryHookResult = ReturnType<typeof useGetAutoCompleteContactsFilterLazyQuery>;
export type GetAutoCompleteContactsFilterQueryResult = Apollo.QueryResult<GetAutoCompleteContactsFilterQuery, GetAutoCompleteContactsFilterQueryVariables>;
export function refetchGetAutoCompleteContactsFilterQuery(variables?: GetAutoCompleteContactsFilterQueryVariables) {
      return { query: GetAutoCompleteContactsFilterDocument, variables: variables }
    }
export const GetAutoCompleteContactsDocument = gql`
    query getAutoCompleteContacts($query: String, $id: Int) {
  contacts(query: $query, id: $id) {
    ...AutocompleteContact
  }
}
    ${AutocompleteContactFragmentDoc}`;

/**
 * __useGetAutoCompleteContactsQuery__
 *
 * To run a query within a React component, call `useGetAutoCompleteContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAutoCompleteContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAutoCompleteContactsQuery({
 *   variables: {
 *      query: // value for 'query'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAutoCompleteContactsQuery(baseOptions?: Apollo.QueryHookOptions<GetAutoCompleteContactsQuery, GetAutoCompleteContactsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAutoCompleteContactsQuery, GetAutoCompleteContactsQueryVariables>(GetAutoCompleteContactsDocument, options);
      }
export function useGetAutoCompleteContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAutoCompleteContactsQuery, GetAutoCompleteContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAutoCompleteContactsQuery, GetAutoCompleteContactsQueryVariables>(GetAutoCompleteContactsDocument, options);
        }
export type GetAutoCompleteContactsQueryHookResult = ReturnType<typeof useGetAutoCompleteContactsQuery>;
export type GetAutoCompleteContactsLazyQueryHookResult = ReturnType<typeof useGetAutoCompleteContactsLazyQuery>;
export type GetAutoCompleteContactsQueryResult = Apollo.QueryResult<GetAutoCompleteContactsQuery, GetAutoCompleteContactsQueryVariables>;
export function refetchGetAutoCompleteContactsQuery(variables?: GetAutoCompleteContactsQueryVariables) {
      return { query: GetAutoCompleteContactsDocument, variables: variables }
    }
export const GetAutoCompleteOrganizationsDocument = gql`
    query getAutoCompleteOrganizations($query: String, $id: Int) {
  organizations(query: $query, id: $id) {
    ...AutocompleteOrganizations
  }
}
    ${AutocompleteOrganizationsFragmentDoc}`;

/**
 * __useGetAutoCompleteOrganizationsQuery__
 *
 * To run a query within a React component, call `useGetAutoCompleteOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAutoCompleteOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAutoCompleteOrganizationsQuery({
 *   variables: {
 *      query: // value for 'query'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAutoCompleteOrganizationsQuery(baseOptions?: Apollo.QueryHookOptions<GetAutoCompleteOrganizationsQuery, GetAutoCompleteOrganizationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAutoCompleteOrganizationsQuery, GetAutoCompleteOrganizationsQueryVariables>(GetAutoCompleteOrganizationsDocument, options);
      }
export function useGetAutoCompleteOrganizationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAutoCompleteOrganizationsQuery, GetAutoCompleteOrganizationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAutoCompleteOrganizationsQuery, GetAutoCompleteOrganizationsQueryVariables>(GetAutoCompleteOrganizationsDocument, options);
        }
export type GetAutoCompleteOrganizationsQueryHookResult = ReturnType<typeof useGetAutoCompleteOrganizationsQuery>;
export type GetAutoCompleteOrganizationsLazyQueryHookResult = ReturnType<typeof useGetAutoCompleteOrganizationsLazyQuery>;
export type GetAutoCompleteOrganizationsQueryResult = Apollo.QueryResult<GetAutoCompleteOrganizationsQuery, GetAutoCompleteOrganizationsQueryVariables>;
export function refetchGetAutoCompleteOrganizationsQuery(variables?: GetAutoCompleteOrganizationsQueryVariables) {
      return { query: GetAutoCompleteOrganizationsDocument, variables: variables }
    }
export const GetAutoCompleteLocationsDocument = gql`
    query getAutoCompleteLocations($query: String, $id: Int) {
  locations(query: $query, id: $id) {
    ...AutoCompleteLocations
  }
}
    ${AutoCompleteLocationsFragmentDoc}`;

/**
 * __useGetAutoCompleteLocationsQuery__
 *
 * To run a query within a React component, call `useGetAutoCompleteLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAutoCompleteLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAutoCompleteLocationsQuery({
 *   variables: {
 *      query: // value for 'query'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAutoCompleteLocationsQuery(baseOptions?: Apollo.QueryHookOptions<GetAutoCompleteLocationsQuery, GetAutoCompleteLocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAutoCompleteLocationsQuery, GetAutoCompleteLocationsQueryVariables>(GetAutoCompleteLocationsDocument, options);
      }
export function useGetAutoCompleteLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAutoCompleteLocationsQuery, GetAutoCompleteLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAutoCompleteLocationsQuery, GetAutoCompleteLocationsQueryVariables>(GetAutoCompleteLocationsDocument, options);
        }
export type GetAutoCompleteLocationsQueryHookResult = ReturnType<typeof useGetAutoCompleteLocationsQuery>;
export type GetAutoCompleteLocationsLazyQueryHookResult = ReturnType<typeof useGetAutoCompleteLocationsLazyQuery>;
export type GetAutoCompleteLocationsQueryResult = Apollo.QueryResult<GetAutoCompleteLocationsQuery, GetAutoCompleteLocationsQueryVariables>;
export function refetchGetAutoCompleteLocationsQuery(variables?: GetAutoCompleteLocationsQueryVariables) {
      return { query: GetAutoCompleteLocationsDocument, variables: variables }
    }
export const GetComboDiscountsDocument = gql`
    query getComboDiscounts {
  comboDiscounts {
    id
    type {
      ...ComboType
    }
    discount1
    products1 {
      id
    }
    discount2
    products2 {
      id
    }
  }
}
    ${ComboTypeFragmentDoc}`;

/**
 * __useGetComboDiscountsQuery__
 *
 * To run a query within a React component, call `useGetComboDiscountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetComboDiscountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetComboDiscountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetComboDiscountsQuery(baseOptions?: Apollo.QueryHookOptions<GetComboDiscountsQuery, GetComboDiscountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetComboDiscountsQuery, GetComboDiscountsQueryVariables>(GetComboDiscountsDocument, options);
      }
export function useGetComboDiscountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetComboDiscountsQuery, GetComboDiscountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetComboDiscountsQuery, GetComboDiscountsQueryVariables>(GetComboDiscountsDocument, options);
        }
export type GetComboDiscountsQueryHookResult = ReturnType<typeof useGetComboDiscountsQuery>;
export type GetComboDiscountsLazyQueryHookResult = ReturnType<typeof useGetComboDiscountsLazyQuery>;
export type GetComboDiscountsQueryResult = Apollo.QueryResult<GetComboDiscountsQuery, GetComboDiscountsQueryVariables>;
export function refetchGetComboDiscountsQuery(variables?: GetComboDiscountsQueryVariables) {
      return { query: GetComboDiscountsDocument, variables: variables }
    }
export const GetComparisonListDocument = gql`
    query getComparisonList($filters: QuotesFiltersInputType) {
  list: comparisonList(filters: $filters) {
    id
    shareUrl
    contact {
      email
      displayName
      account {
        taxId
      }
    }
    created
    paymentMethods {
      name
    }
    items {
      name
      band {
        name
      }
      quantity
    }
  }
}
    `;

/**
 * __useGetComparisonListQuery__
 *
 * To run a query within a React component, call `useGetComparisonListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetComparisonListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetComparisonListQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetComparisonListQuery(baseOptions?: Apollo.QueryHookOptions<GetComparisonListQuery, GetComparisonListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetComparisonListQuery, GetComparisonListQueryVariables>(GetComparisonListDocument, options);
      }
export function useGetComparisonListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetComparisonListQuery, GetComparisonListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetComparisonListQuery, GetComparisonListQueryVariables>(GetComparisonListDocument, options);
        }
export type GetComparisonListQueryHookResult = ReturnType<typeof useGetComparisonListQuery>;
export type GetComparisonListLazyQueryHookResult = ReturnType<typeof useGetComparisonListLazyQuery>;
export type GetComparisonListQueryResult = Apollo.QueryResult<GetComparisonListQuery, GetComparisonListQueryVariables>;
export function refetchGetComparisonListQuery(variables?: GetComparisonListQueryVariables) {
      return { query: GetComparisonListDocument, variables: variables }
    }
export const GetCurrentEventsDocument = gql`
    query getCurrentEvents {
  currentEvents {
    id
    name
  }
}
    `;

/**
 * __useGetCurrentEventsQuery__
 *
 * To run a query within a React component, call `useGetCurrentEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentEventsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentEventsQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentEventsQuery, GetCurrentEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentEventsQuery, GetCurrentEventsQueryVariables>(GetCurrentEventsDocument, options);
      }
export function useGetCurrentEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentEventsQuery, GetCurrentEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentEventsQuery, GetCurrentEventsQueryVariables>(GetCurrentEventsDocument, options);
        }
export type GetCurrentEventsQueryHookResult = ReturnType<typeof useGetCurrentEventsQuery>;
export type GetCurrentEventsLazyQueryHookResult = ReturnType<typeof useGetCurrentEventsLazyQuery>;
export type GetCurrentEventsQueryResult = Apollo.QueryResult<GetCurrentEventsQuery, GetCurrentEventsQueryVariables>;
export function refetchGetCurrentEventsQuery(variables?: GetCurrentEventsQueryVariables) {
      return { query: GetCurrentEventsDocument, variables: variables }
    }
export const GetEventsDocument = gql`
    query getEvents {
  events {
    id
    name
  }
}
    `;

/**
 * __useGetEventsQuery__
 *
 * To run a query within a React component, call `useGetEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEventsQuery(baseOptions?: Apollo.QueryHookOptions<GetEventsQuery, GetEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEventsQuery, GetEventsQueryVariables>(GetEventsDocument, options);
      }
export function useGetEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEventsQuery, GetEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEventsQuery, GetEventsQueryVariables>(GetEventsDocument, options);
        }
export type GetEventsQueryHookResult = ReturnType<typeof useGetEventsQuery>;
export type GetEventsLazyQueryHookResult = ReturnType<typeof useGetEventsLazyQuery>;
export type GetEventsQueryResult = Apollo.QueryResult<GetEventsQuery, GetEventsQueryVariables>;
export function refetchGetEventsQuery(variables?: GetEventsQueryVariables) {
      return { query: GetEventsDocument, variables: variables }
    }
export const GetDiscountLimitsDocument = gql`
    query getDiscountLimits {
  discountLimits {
    ...DiscountLimits
  }
}
    ${DiscountLimitsFragmentDoc}`;

/**
 * __useGetDiscountLimitsQuery__
 *
 * To run a query within a React component, call `useGetDiscountLimitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDiscountLimitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDiscountLimitsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDiscountLimitsQuery(baseOptions?: Apollo.QueryHookOptions<GetDiscountLimitsQuery, GetDiscountLimitsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDiscountLimitsQuery, GetDiscountLimitsQueryVariables>(GetDiscountLimitsDocument, options);
      }
export function useGetDiscountLimitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDiscountLimitsQuery, GetDiscountLimitsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDiscountLimitsQuery, GetDiscountLimitsQueryVariables>(GetDiscountLimitsDocument, options);
        }
export type GetDiscountLimitsQueryHookResult = ReturnType<typeof useGetDiscountLimitsQuery>;
export type GetDiscountLimitsLazyQueryHookResult = ReturnType<typeof useGetDiscountLimitsLazyQuery>;
export type GetDiscountLimitsQueryResult = Apollo.QueryResult<GetDiscountLimitsQuery, GetDiscountLimitsQueryVariables>;
export function refetchGetDiscountLimitsQuery(variables?: GetDiscountLimitsQueryVariables) {
      return { query: GetDiscountLimitsDocument, variables: variables }
    }
export const GetPaymentMethodsDocument = gql`
    query getPaymentMethods {
  paymentMethods {
    ...PaymentMethods
  }
}
    ${PaymentMethodsFragmentDoc}`;

/**
 * __useGetPaymentMethodsQuery__
 *
 * To run a query within a React component, call `useGetPaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentMethodsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPaymentMethodsQuery(baseOptions?: Apollo.QueryHookOptions<GetPaymentMethodsQuery, GetPaymentMethodsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaymentMethodsQuery, GetPaymentMethodsQueryVariables>(GetPaymentMethodsDocument, options);
      }
export function useGetPaymentMethodsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaymentMethodsQuery, GetPaymentMethodsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaymentMethodsQuery, GetPaymentMethodsQueryVariables>(GetPaymentMethodsDocument, options);
        }
export type GetPaymentMethodsQueryHookResult = ReturnType<typeof useGetPaymentMethodsQuery>;
export type GetPaymentMethodsLazyQueryHookResult = ReturnType<typeof useGetPaymentMethodsLazyQuery>;
export type GetPaymentMethodsQueryResult = Apollo.QueryResult<GetPaymentMethodsQuery, GetPaymentMethodsQueryVariables>;
export function refetchGetPaymentMethodsQuery(variables?: GetPaymentMethodsQueryVariables) {
      return { query: GetPaymentMethodsDocument, variables: variables }
    }
export const GetProductCategoriesDocument = gql`
    query getProductCategories {
  productCategories {
    ...Category
  }
}
    ${CategoryFragmentDoc}`;

/**
 * __useGetProductCategoriesQuery__
 *
 * To run a query within a React component, call `useGetProductCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProductCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetProductCategoriesQuery, GetProductCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductCategoriesQuery, GetProductCategoriesQueryVariables>(GetProductCategoriesDocument, options);
      }
export function useGetProductCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductCategoriesQuery, GetProductCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductCategoriesQuery, GetProductCategoriesQueryVariables>(GetProductCategoriesDocument, options);
        }
export type GetProductCategoriesQueryHookResult = ReturnType<typeof useGetProductCategoriesQuery>;
export type GetProductCategoriesLazyQueryHookResult = ReturnType<typeof useGetProductCategoriesLazyQuery>;
export type GetProductCategoriesQueryResult = Apollo.QueryResult<GetProductCategoriesQuery, GetProductCategoriesQueryVariables>;
export function refetchGetProductCategoriesQuery(variables?: GetProductCategoriesQueryVariables) {
      return { query: GetProductCategoriesDocument, variables: variables }
    }
export const GetProductsForQuoteDocument = gql`
    query getProductsForQuote {
  productsForQuote {
    ...ProductsForQuote
    category {
      ...Category
    }
    bands {
      ...Bands
    }
    unit {
      ...Unit
    }
  }
  productBands {
    ...ProductBands
  }
}
    ${ProductsForQuoteFragmentDoc}
${CategoryFragmentDoc}
${BandsFragmentDoc}
${UnitFragmentDoc}
${ProductBandsFragmentDoc}`;

/**
 * __useGetProductsForQuoteQuery__
 *
 * To run a query within a React component, call `useGetProductsForQuoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsForQuoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsForQuoteQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProductsForQuoteQuery(baseOptions?: Apollo.QueryHookOptions<GetProductsForQuoteQuery, GetProductsForQuoteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductsForQuoteQuery, GetProductsForQuoteQueryVariables>(GetProductsForQuoteDocument, options);
      }
export function useGetProductsForQuoteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductsForQuoteQuery, GetProductsForQuoteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductsForQuoteQuery, GetProductsForQuoteQueryVariables>(GetProductsForQuoteDocument, options);
        }
export type GetProductsForQuoteQueryHookResult = ReturnType<typeof useGetProductsForQuoteQuery>;
export type GetProductsForQuoteLazyQueryHookResult = ReturnType<typeof useGetProductsForQuoteLazyQuery>;
export type GetProductsForQuoteQueryResult = Apollo.QueryResult<GetProductsForQuoteQuery, GetProductsForQuoteQueryVariables>;
export function refetchGetProductsForQuoteQuery(variables?: GetProductsForQuoteQueryVariables) {
      return { query: GetProductsForQuoteDocument, variables: variables }
    }
export const GetProductsForRecommendationDocument = gql`
    query getProductsForRecommendation {
  productsForRecommendation {
    id
    name
    category {
      ...Category
    }
  }
}
    ${CategoryFragmentDoc}`;

/**
 * __useGetProductsForRecommendationQuery__
 *
 * To run a query within a React component, call `useGetProductsForRecommendationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsForRecommendationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsForRecommendationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProductsForRecommendationQuery(baseOptions?: Apollo.QueryHookOptions<GetProductsForRecommendationQuery, GetProductsForRecommendationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductsForRecommendationQuery, GetProductsForRecommendationQueryVariables>(GetProductsForRecommendationDocument, options);
      }
export function useGetProductsForRecommendationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductsForRecommendationQuery, GetProductsForRecommendationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductsForRecommendationQuery, GetProductsForRecommendationQueryVariables>(GetProductsForRecommendationDocument, options);
        }
export type GetProductsForRecommendationQueryHookResult = ReturnType<typeof useGetProductsForRecommendationQuery>;
export type GetProductsForRecommendationLazyQueryHookResult = ReturnType<typeof useGetProductsForRecommendationLazyQuery>;
export type GetProductsForRecommendationQueryResult = Apollo.QueryResult<GetProductsForRecommendationQuery, GetProductsForRecommendationQueryVariables>;
export function refetchGetProductsForRecommendationQuery(variables?: GetProductsForRecommendationQueryVariables) {
      return { query: GetProductsForRecommendationDocument, variables: variables }
    }
export const GetQuoteExpirationTimeDocument = gql`
    query getQuoteExpirationTime {
  quoteExpirationTimeLimit {
    minimumDays
    maximumDays
  }
}
    `;

/**
 * __useGetQuoteExpirationTimeQuery__
 *
 * To run a query within a React component, call `useGetQuoteExpirationTimeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuoteExpirationTimeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuoteExpirationTimeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetQuoteExpirationTimeQuery(baseOptions?: Apollo.QueryHookOptions<GetQuoteExpirationTimeQuery, GetQuoteExpirationTimeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuoteExpirationTimeQuery, GetQuoteExpirationTimeQueryVariables>(GetQuoteExpirationTimeDocument, options);
      }
export function useGetQuoteExpirationTimeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuoteExpirationTimeQuery, GetQuoteExpirationTimeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuoteExpirationTimeQuery, GetQuoteExpirationTimeQueryVariables>(GetQuoteExpirationTimeDocument, options);
        }
export type GetQuoteExpirationTimeQueryHookResult = ReturnType<typeof useGetQuoteExpirationTimeQuery>;
export type GetQuoteExpirationTimeLazyQueryHookResult = ReturnType<typeof useGetQuoteExpirationTimeLazyQuery>;
export type GetQuoteExpirationTimeQueryResult = Apollo.QueryResult<GetQuoteExpirationTimeQuery, GetQuoteExpirationTimeQueryVariables>;
export function refetchGetQuoteExpirationTimeQuery(variables?: GetQuoteExpirationTimeQueryVariables) {
      return { query: GetQuoteExpirationTimeDocument, variables: variables }
    }
export const GetQuoteListDocument = gql`
    query getQuoteList($filters: QuotesFiltersInputType) {
  list: quoteList(filters: $filters) {
    id
    shareUrl
    contact {
      email
      phone
      displayName
      account {
        taxId
        name
      }
    }
    created
    status
    statusDisplay
    expirationDate
    paymentMethods {
      name
    }
    items {
      name
      band {
        name
      }
      quantity
    }
  }
}
    `;

/**
 * __useGetQuoteListQuery__
 *
 * To run a query within a React component, call `useGetQuoteListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuoteListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuoteListQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetQuoteListQuery(baseOptions?: Apollo.QueryHookOptions<GetQuoteListQuery, GetQuoteListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuoteListQuery, GetQuoteListQueryVariables>(GetQuoteListDocument, options);
      }
export function useGetQuoteListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuoteListQuery, GetQuoteListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuoteListQuery, GetQuoteListQueryVariables>(GetQuoteListDocument, options);
        }
export type GetQuoteListQueryHookResult = ReturnType<typeof useGetQuoteListQuery>;
export type GetQuoteListLazyQueryHookResult = ReturnType<typeof useGetQuoteListLazyQuery>;
export type GetQuoteListQueryResult = Apollo.QueryResult<GetQuoteListQuery, GetQuoteListQueryVariables>;
export function refetchGetQuoteListQuery(variables?: GetQuoteListQueryVariables) {
      return { query: GetQuoteListDocument, variables: variables }
    }
export const GetQuoteDocument = gql`
    query getQuote($id: Int!) {
  quote(id: $id) {
    id
    shareUrl
    contact {
      email
      phone
      displayName
      account {
        taxId
      }
    }
    created
    status
    statusDisplay
    expirationDate
    quotePaymentMethods {
      ...QuotePaymentMethods
    }
    items {
      name
      price
      quantity
      bandDiscount
      volumeDiscount
      nideraDiscount
      organizationDiscount
      totalComboDiscount
      category {
        id
        name
        bands {
          ...ProductBands
        }
      }
      product {
        id
        unit {
          ...Unit
        }
      }
      band {
        discount
        id
      }
    }
  }
}
    ${QuotePaymentMethodsFragmentDoc}
${ProductBandsFragmentDoc}
${UnitFragmentDoc}`;

/**
 * __useGetQuoteQuery__
 *
 * To run a query within a React component, call `useGetQuoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuoteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetQuoteQuery(baseOptions: Apollo.QueryHookOptions<GetQuoteQuery, GetQuoteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuoteQuery, GetQuoteQueryVariables>(GetQuoteDocument, options);
      }
export function useGetQuoteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuoteQuery, GetQuoteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuoteQuery, GetQuoteQueryVariables>(GetQuoteDocument, options);
        }
export type GetQuoteQueryHookResult = ReturnType<typeof useGetQuoteQuery>;
export type GetQuoteLazyQueryHookResult = ReturnType<typeof useGetQuoteLazyQuery>;
export type GetQuoteQueryResult = Apollo.QueryResult<GetQuoteQuery, GetQuoteQueryVariables>;
export function refetchGetQuoteQuery(variables: GetQuoteQueryVariables) {
      return { query: GetQuoteDocument, variables: variables }
    }
export const GetRecommendationListDocument = gql`
    query getRecommendationList($filters: RecommendationsFiltersInputType) {
  list: recommendationList(filters: $filters) {
    id
    created
    shareUrl
    contact {
      email
      phone
      displayName
      account {
        taxId
      }
    }
    productsByCategory {
      category {
        name
      }
      products {
        name
      }
    }
  }
}
    `;

/**
 * __useGetRecommendationListQuery__
 *
 * To run a query within a React component, call `useGetRecommendationListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecommendationListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecommendationListQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetRecommendationListQuery(baseOptions?: Apollo.QueryHookOptions<GetRecommendationListQuery, GetRecommendationListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRecommendationListQuery, GetRecommendationListQueryVariables>(GetRecommendationListDocument, options);
      }
export function useGetRecommendationListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRecommendationListQuery, GetRecommendationListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRecommendationListQuery, GetRecommendationListQueryVariables>(GetRecommendationListDocument, options);
        }
export type GetRecommendationListQueryHookResult = ReturnType<typeof useGetRecommendationListQuery>;
export type GetRecommendationListLazyQueryHookResult = ReturnType<typeof useGetRecommendationListLazyQuery>;
export type GetRecommendationListQueryResult = Apollo.QueryResult<GetRecommendationListQuery, GetRecommendationListQueryVariables>;
export function refetchGetRecommendationListQuery(variables?: GetRecommendationListQueryVariables) {
      return { query: GetRecommendationListDocument, variables: variables }
    }
export const GetQuotesAnalyticsDocument = gql`
    query getQuotesAnalytics($filters: AnalyticsFilters!) {
  quotesAnalytics(filters: $filters) {
    ...QuotesAnalytics
  }
}
    ${QuotesAnalyticsFragmentDoc}`;

/**
 * __useGetQuotesAnalyticsQuery__
 *
 * To run a query within a React component, call `useGetQuotesAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuotesAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuotesAnalyticsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetQuotesAnalyticsQuery(baseOptions: Apollo.QueryHookOptions<GetQuotesAnalyticsQuery, GetQuotesAnalyticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuotesAnalyticsQuery, GetQuotesAnalyticsQueryVariables>(GetQuotesAnalyticsDocument, options);
      }
export function useGetQuotesAnalyticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuotesAnalyticsQuery, GetQuotesAnalyticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuotesAnalyticsQuery, GetQuotesAnalyticsQueryVariables>(GetQuotesAnalyticsDocument, options);
        }
export type GetQuotesAnalyticsQueryHookResult = ReturnType<typeof useGetQuotesAnalyticsQuery>;
export type GetQuotesAnalyticsLazyQueryHookResult = ReturnType<typeof useGetQuotesAnalyticsLazyQuery>;
export type GetQuotesAnalyticsQueryResult = Apollo.QueryResult<GetQuotesAnalyticsQuery, GetQuotesAnalyticsQueryVariables>;
export function refetchGetQuotesAnalyticsQuery(variables: GetQuotesAnalyticsQueryVariables) {
      return { query: GetQuotesAnalyticsDocument, variables: variables }
    }
export const GetRecommendationsAnalyticsDocument = gql`
    query getRecommendationsAnalytics($filters: AnalyticsFilters!) {
  recommendationsAnalytics(filters: $filters) {
    ...RecommendationsAnalytics
  }
}
    ${RecommendationsAnalyticsFragmentDoc}`;

/**
 * __useGetRecommendationsAnalyticsQuery__
 *
 * To run a query within a React component, call `useGetRecommendationsAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecommendationsAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecommendationsAnalyticsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetRecommendationsAnalyticsQuery(baseOptions: Apollo.QueryHookOptions<GetRecommendationsAnalyticsQuery, GetRecommendationsAnalyticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRecommendationsAnalyticsQuery, GetRecommendationsAnalyticsQueryVariables>(GetRecommendationsAnalyticsDocument, options);
      }
export function useGetRecommendationsAnalyticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRecommendationsAnalyticsQuery, GetRecommendationsAnalyticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRecommendationsAnalyticsQuery, GetRecommendationsAnalyticsQueryVariables>(GetRecommendationsAnalyticsDocument, options);
        }
export type GetRecommendationsAnalyticsQueryHookResult = ReturnType<typeof useGetRecommendationsAnalyticsQuery>;
export type GetRecommendationsAnalyticsLazyQueryHookResult = ReturnType<typeof useGetRecommendationsAnalyticsLazyQuery>;
export type GetRecommendationsAnalyticsQueryResult = Apollo.QueryResult<GetRecommendationsAnalyticsQuery, GetRecommendationsAnalyticsQueryVariables>;
export function refetchGetRecommendationsAnalyticsQuery(variables: GetRecommendationsAnalyticsQueryVariables) {
      return { query: GetRecommendationsAnalyticsDocument, variables: variables }
    }
export const UpsertContactDocument = gql`
    mutation upsertContact($accountId: ID, $name: String, $taxId: String!, $locations: [Int]!, $contactId: ID, $email: String!, $firstName: String, $lastName: String, $phone: String) {
  upsertContact(
    account: $accountId
    name: $name
    taxId: $taxId
    locations: $locations
    contact: $contactId
    email: $email
    firstName: $firstName
    lastName: $lastName
    phone: $phone
  ) {
    contact {
      id
    }
    success
    errors
  }
}
    `;
export type UpsertContactMutationFn = Apollo.MutationFunction<UpsertContactMutation, UpsertContactMutationVariables>;

/**
 * __useUpsertContactMutation__
 *
 * To run a mutation, you first call `useUpsertContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertContactMutation, { data, loading, error }] = useUpsertContactMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      name: // value for 'name'
 *      taxId: // value for 'taxId'
 *      locations: // value for 'locations'
 *      contactId: // value for 'contactId'
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useUpsertContactMutation(baseOptions?: Apollo.MutationHookOptions<UpsertContactMutation, UpsertContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertContactMutation, UpsertContactMutationVariables>(UpsertContactDocument, options);
      }
export type UpsertContactMutationHookResult = ReturnType<typeof useUpsertContactMutation>;
export type UpsertContactMutationResult = Apollo.MutationResult<UpsertContactMutation>;
export type UpsertContactMutationOptions = Apollo.BaseMutationOptions<UpsertContactMutation, UpsertContactMutationVariables>;
export const CreateQuoteDocument = gql`
    mutation createQuote($contact: Int!, $paymentMethods: [QuotePaymentMethodInput]!, $notes: String, $items: [QuoteItemInput]!, $organization: Int, $event: Int, $isComparison: Boolean!, $productsTechnicalInfo: [String], $status: String, $expirationDate: String, $pdfPaymentMethodDetail: Boolean!) {
  createQuote(
    contact: $contact
    paymentMethods: $paymentMethods
    items: $items
    notes: $notes
    organization: $organization
    event: $event
    isComparison: $isComparison
    productsTechnicalInfo: $productsTechnicalInfo
    status: $status
    expirationDate: $expirationDate
    pdfPaymentMethodDetail: $pdfPaymentMethodDetail
  ) {
    success
    errors
    quote {
      shareUrl
      contact {
        phone
      }
      id
    }
  }
}
    `;
export type CreateQuoteMutationFn = Apollo.MutationFunction<CreateQuoteMutation, CreateQuoteMutationVariables>;

/**
 * __useCreateQuoteMutation__
 *
 * To run a mutation, you first call `useCreateQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuoteMutation, { data, loading, error }] = useCreateQuoteMutation({
 *   variables: {
 *      contact: // value for 'contact'
 *      paymentMethods: // value for 'paymentMethods'
 *      notes: // value for 'notes'
 *      items: // value for 'items'
 *      organization: // value for 'organization'
 *      event: // value for 'event'
 *      isComparison: // value for 'isComparison'
 *      productsTechnicalInfo: // value for 'productsTechnicalInfo'
 *      status: // value for 'status'
 *      expirationDate: // value for 'expirationDate'
 *      pdfPaymentMethodDetail: // value for 'pdfPaymentMethodDetail'
 *   },
 * });
 */
export function useCreateQuoteMutation(baseOptions?: Apollo.MutationHookOptions<CreateQuoteMutation, CreateQuoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateQuoteMutation, CreateQuoteMutationVariables>(CreateQuoteDocument, options);
      }
export type CreateQuoteMutationHookResult = ReturnType<typeof useCreateQuoteMutation>;
export type CreateQuoteMutationResult = Apollo.MutationResult<CreateQuoteMutation>;
export type CreateQuoteMutationOptions = Apollo.BaseMutationOptions<CreateQuoteMutation, CreateQuoteMutationVariables>;
export const CreateRecommendationDocument = gql`
    mutation createRecommendation($contact: Int!, $products: [Int]!, $notes: String, $organization: Int, $event: Int, $seller: String) {
  createRecommendation(
    contact: $contact
    products: $products
    notes: $notes
    organization: $organization
    event: $event
    seller: $seller
  ) {
    success
    errors
    recommendation {
      shareUrl
      contact {
        phone
      }
    }
  }
}
    `;
export type CreateRecommendationMutationFn = Apollo.MutationFunction<CreateRecommendationMutation, CreateRecommendationMutationVariables>;

/**
 * __useCreateRecommendationMutation__
 *
 * To run a mutation, you first call `useCreateRecommendationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRecommendationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRecommendationMutation, { data, loading, error }] = useCreateRecommendationMutation({
 *   variables: {
 *      contact: // value for 'contact'
 *      products: // value for 'products'
 *      notes: // value for 'notes'
 *      organization: // value for 'organization'
 *      event: // value for 'event'
 *      seller: // value for 'seller'
 *   },
 * });
 */
export function useCreateRecommendationMutation(baseOptions?: Apollo.MutationHookOptions<CreateRecommendationMutation, CreateRecommendationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRecommendationMutation, CreateRecommendationMutationVariables>(CreateRecommendationDocument, options);
      }
export type CreateRecommendationMutationHookResult = ReturnType<typeof useCreateRecommendationMutation>;
export type CreateRecommendationMutationResult = Apollo.MutationResult<CreateRecommendationMutation>;
export type CreateRecommendationMutationOptions = Apollo.BaseMutationOptions<CreateRecommendationMutation, CreateRecommendationMutationVariables>;
export const ResendQuoteDocument = gql`
    mutation resendQuote($id: Int!) {
  resendQuote(id: $id) {
    success
    errors
  }
}
    `;
export type ResendQuoteMutationFn = Apollo.MutationFunction<ResendQuoteMutation, ResendQuoteMutationVariables>;

/**
 * __useResendQuoteMutation__
 *
 * To run a mutation, you first call `useResendQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendQuoteMutation, { data, loading, error }] = useResendQuoteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResendQuoteMutation(baseOptions?: Apollo.MutationHookOptions<ResendQuoteMutation, ResendQuoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendQuoteMutation, ResendQuoteMutationVariables>(ResendQuoteDocument, options);
      }
export type ResendQuoteMutationHookResult = ReturnType<typeof useResendQuoteMutation>;
export type ResendQuoteMutationResult = Apollo.MutationResult<ResendQuoteMutation>;
export type ResendQuoteMutationOptions = Apollo.BaseMutationOptions<ResendQuoteMutation, ResendQuoteMutationVariables>;
export const ResendRecommendationDocument = gql`
    mutation resendRecommendation($id: Int!) {
  resendRecommendation(id: $id) {
    success
    errors
  }
}
    `;
export type ResendRecommendationMutationFn = Apollo.MutationFunction<ResendRecommendationMutation, ResendRecommendationMutationVariables>;

/**
 * __useResendRecommendationMutation__
 *
 * To run a mutation, you first call `useResendRecommendationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendRecommendationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendRecommendationMutation, { data, loading, error }] = useResendRecommendationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResendRecommendationMutation(baseOptions?: Apollo.MutationHookOptions<ResendRecommendationMutation, ResendRecommendationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendRecommendationMutation, ResendRecommendationMutationVariables>(ResendRecommendationDocument, options);
      }
export type ResendRecommendationMutationHookResult = ReturnType<typeof useResendRecommendationMutation>;
export type ResendRecommendationMutationResult = Apollo.MutationResult<ResendRecommendationMutation>;
export type ResendRecommendationMutationOptions = Apollo.BaseMutationOptions<ResendRecommendationMutation, ResendRecommendationMutationVariables>;