import {useState} from 'react'

export const useToggle = (initialValue: boolean | undefined = false) => {
    const [state, setState] = useState(initialValue)

    const toggle = () => setState(curr => !curr)

    const setOpen = () => setState(true)
    const setClose = () => setState(false)

    return {open: state, toggle, setOpen, setClose, setOpenState: setState}
}
