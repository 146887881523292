import {PermIdentityOutlined as PermIdentityOutlinedIcon} from '@mui/icons-material';
import {Theme, useMediaQuery, Typography} from '@mui/material';
import {createStyles, makeStyles} from '@mui/styles';
import {useRouter} from 'next/router';
import React, {FC} from 'react';
import {useUser} from 'contexts/user';

const UserMenu: FC = () => {
    const classes = useStyles();
    const {user} = useUser(); 

    return (
        <div className={classes.root}>
            {user && (
                <Typography>
                   <strong>{user.organization?.name}</strong>
                </Typography>
            )}
            <PermIdentityOutlinedIcon style={{stroke: 'grey', strokeWidth: '0.05'}} />
            {user && (
                <Typography>
                    {user.fullName}
                </Typography>
            )}
        </div>
    );
};

export default UserMenu;


const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        display: 'flex',
        marginRight: theme.spacing(2),
        '& > * + *': {
            margin: `${theme.spacing(0)} ${theme.spacing(0.5)}`,
        }
    },
}));
