import {Theme, Container, ListItem, ListItemText, Avatar, List, Hidden} from '@mui/material';
import {createStyles, makeStyles} from '@mui/styles';
import Link from 'next/link';
import React, {FC} from 'react';

export const AppFooter: FC = () => {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <Container className={classes.container}>
                <List className={classes.socials}>
                    <a href='https://niderasemillas.com.ar/' target='_blank' rel='noopener noreferrer'>
                        <ListItem>
                            <ListItemText><strong>niderasemillas.com.ar</strong></ListItemText>
                        </ListItem>
                    </a>
                    <a href='https://es-la.facebook.com/NideraSemillas/' target='_blank' rel='noopener noreferrer'>
                        <ListItem>
                            <Avatar className={classes.avatar} src={require('./assets/icon_facebook.svg')} alt='Facebook' />
                            <ListItemText className={classes.listItem}>Niderasemillas</ListItemText>
                        </ListItem>
                    </a>
                    <a href='https://twitter.com/niderasemillas' target='_blank' rel='noopener noreferrer'>
                        <ListItem>
                            <Avatar className={classes.avatar} src={require('./assets/icon_twitter.svg')} alt='Twitter' />
                            <ListItemText className={classes.listItem}>@niderasemillas</ListItemText>
                        </ListItem>
                    </a>
                    <a href='https://instagram.com/niderasemillas' target='_blank' rel='noopener noreferrer'>
                        <ListItem>
                            <Avatar className={classes.avatar} src={require('./assets/icon_instagram.svg')} alt='Instagram' />
                            <ListItemText className={classes.listItem}>@NideraSemillas</ListItemText>
                        </ListItem>
                    </a>
                </List>
                <Hidden mdDown={true}>
                    <div className={classes.background}>
                        <img src={require('./assets/footer-bg.svg')} alt='background' />
                    </div>
                    <div className={classes.logo}>
                        <Link href='http://www.niderasemillas.com.ar' passHref={true}>
                            <img src={require('./assets/logo-nidera.svg')} alt='logo nidera' />
                        </Link>
                    </div>
                </Hidden>
            </Container>
        </div>
    )
};

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        padding: `${theme.spacing(2)} ${theme.spacing(2)}`,
        backgroundColor: theme.palette.secondary.main,
        color: '#FFF',
        height: '14rem',
    },
    container: {
        display: 'flex',
        padding: theme.spacing(0),
    },
    background: {
        opacity: '0.4',
        width: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
    },
    logo: {
        paddingTop: theme.spacing(2),
        '& > img': {
            width: '7rem',
        },
    },
    listItem: {
        paddingLeft: theme.spacing(1),
    },
    avatar: {
        width: '1.5rem',
        height: '1.5rem',
    },
    socials: {
        display: 'flex',
        flexDirection: 'column',
        '& > a': {
            textDecoration: 'none',
            color: 'inherit',
        },
        '& li': {
            paddingTop: '0px',
            paddingBottom: '0px',
        }
    },
}))
